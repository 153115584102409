export const generalMixins = {
    clickOnId(id) {
        document.getElementById(id).click();
    },

    search(form) {
        form.get('', {
            onStart: () => {
                this.searchLoading = true;
            },
            onSuccess: () => {
                this.searchLoading = false;
            }
        });
    },

    removeDivThatHasClass(className) {
        let div = document.getElementsByClassName(className);
        if (div.length > 0) {
            div[0].remove();
        }
    },

    reloadPage() {
        this.$inertia.reload({
            preserveScroll: true,
            onStart: () => {
                this.r.emit('loading', false);
            }
        });
    },

    hideJModal() {
        var elementsToRemove = document.querySelectorAll('.widget__modal-container__item');

        elementsToRemove.forEach(function (element) {
            element.parentNode.removeChild(element);
        });

        const box = document.getElementById('body');
        box.style.overflowY = 'auto';
    },

    user() {
        return this.$page.props.user;
    },
}

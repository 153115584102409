import {createRouter, createWebHistory} from 'vue-router';
import { localeMixins} from "@/mixins/locale_mixin";

const routes = [
    {
        name: 'index-page',
        path: '/',
        component: () => import('@/pages/index-page.vue'),
        meta: {
            title: 'Main Page'
        },
    },
    {
        name: 'about-page',
        path: '/about-us',
        component: () => import('@/pages/about-page.vue'),
        meta: {
            title: 'About Us'
        }
    },
    {
        name: 'services-page',
        path: '/services',
        component: () => import('@/pages/services-page.vue'),
        meta: {
            title: 'Services'
        }
    },
    {
        name: 'contact-page',
        path: '/contact',
        component: () => import('@/pages/contact-page.vue'),
        meta: {
            title: 'Contact Us'
        }
    },
    {
        name: 'possibilities-page',
        path: '/possibilities',
        component: () => import('@/pages/possibilities-page.vue'),
        meta: {
            title: 'Possibilities'
        }
    },
    {
        name: 'design-portfolio',
        path: '/portfolio/design',
        component: () => import('@/pages/portfolio/design-portfolio.vue'),
        meta: {
            title: 'Design'
        }
    },
    {
        name: '404-page',
        path: '/:catchAll(.*)',
        component: () => import('@/pages/404-page.vue'),
        meta: {
            title: 'Error not found'
        }
    },
];
const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return {top: 0}
    },
});
router.beforeEach(async (to) => {
    document.title = `CIS | ${await localeMixins.__(to.meta?.title)}` || await localeMixins.__('Code it sync');
    document.getElementById('navbarNav').classList.remove('show')
    document.getElementsByClassName('offcanvas-backdrop')[0]?.remove();
});

export default router;
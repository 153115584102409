import {defineStore} from "pinia";

export const useThemeStore = defineStore('example', {
    state: () => ({
        theme: localStorage.getItem('theme') || 'light',
    }),
    getters: {
        getLogo(state) {
            switch (state.theme) {
                case 'light':
                    return require('@/assets/logo.png');
                case 'dark':
                    return require('@/assets/logo-white.png');
                default:
                    return require('@/assets/logo.png');
            }
        },
        getTheme(state) {
            return state.theme;
        },
    },
    actions: {
        toggleTheme() {
            const htmlTag = document.documentElement;

            if (this.theme === 'dark') {
                this.theme = 'light';
                localStorage.setItem('theme', 'light');
                htmlTag.setAttribute('data-bs-theme', 'light');
            } else {
                this.theme = 'dark';
                localStorage.setItem('theme', 'dark');
                htmlTag.setAttribute('data-bs-theme', 'dark');
            }
        },
    },
});
<script setup>
import {useThemeStore} from "@/pinia/theme";
let themeStore = useThemeStore();
</script>

<template>
  <header class="header navbar navbar-expand-lg">
    <div class="container px-3">
      <router-link :to="{name: 'index-page'}" class="navbar-brand pe-3">
        <img :src="themeStore.getLogo" alt="Code it sync">
      </router-link>
      <div id="navbarNav" class="offcanvas offcanvas-end bg-dark">
        <div class="offcanvas-header border-bottom border-light">
          <h5 class="offcanvas-title text-white">Menu</h5>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <router-link :to="{name: 'index-page'}" class="nav-link text-nowrap">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{name: 'about-page'}" class="nav-link text-nowrap">About us</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{name: 'contact-page'}" class="nav-link text-nowrap">Contact us</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="pe-lg-1 ms-auto me-4" data-bs-theme="dark">
        <div class="form-check form-switch mode-switch" data-bs-toggle="mode">
          <input type="checkbox" class="form-check-input" id="theme-mode" @click="themeStore.toggleTheme">
          <label class="form-check-label d-none d-sm-block" for="theme-mode">Light</label>
          <label class="form-check-label d-none d-sm-block" for="theme-mode">Dark</label>
        </div>
      </div>
      <button type="button" class="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
  </header>
</template>

<style scoped>

</style>
import { createPinia } from 'pinia';
const pinia = createPinia();

/********************************* plugins ****************************/
function settingPlugin() {
    return {
        mainTitle: 'Code it sync',
        lang: localStorage.getItem('lang') || 'ar',
    };
}
/******************************** pinia use ***************************/
pinia.use(settingPlugin);

/********************** export default pinia **************************/
export default pinia;
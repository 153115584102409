/************************** imports ******************************/
import { createApp } from 'vue'
import App from './App.vue'
import pinia from '@/pinia'
import router from "@/router";
import {mixin} from "@/mixins";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

/************************** constants ******************************/
const app = createApp(App);

/************************** app use ******************************/
app.use(pinia)
app.mixin({
    methods: mixin
})
app.use(router)


/************************** set theme ******************************/
const htmlTag = document.documentElement;
const theme = localStorage.getItem('theme');
htmlTag.setAttribute('data-bs-theme', theme || 'light');
/************************** app mount ******************************/
app.mount('#app');

<template>
  <main class="page-wrapper">
    <header-include/>
    <router-view/>
    <footer-include/>
  </main>
</template>

<script>
import FooterInclude from "@/pages/include/footer-include.vue";
import HeaderInclude from "@/pages/include/header-include.vue";

import('@/assets/js/theme-switcher.js')
import('@/assets/vendor/boxicons/css/boxicons.min.css')
import('@/assets/vendor/swiper/swiper-bundle.min.css')
import('@/assets/vendor/lightgallery/css/lightgallery-bundle.min.css')
import('@/assets/css/theme.min.css')

export default {
  name: 'App',
  components: {FooterInclude, HeaderInclude},
}
</script>
let translate = await import((`@/lang/${localStorage.getItem('lang') || 'en'}.json`))
export const localeMixins = {
    __(value) {
        try {
            let data = translate[value];
            if (data) return data;
            return value;
        } catch (e) {
            return value;
        }
    },

    async setLang(lang) {
        localStorage.setItem('lang', lang);
        window.location.reload();
    },

    lang() {
        return localStorage.getItem('lang')
    },

    exLang() {
        if (this.lang() === 'ar') {
            return 'en';
        }
        return 'ar';
    },

    dir(lang) {
        if (lang === 'ar') {
            return 'rtl';
        }
        return 'ltr';
    },
}
